<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Block Out Date
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="blockOutDateCreateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card
          class="px-3 py-2"
        >
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Name*"
                label-for="h-blockOutDate-name"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-blockOutDate-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Start and End Date*"
                label-for="h-blockOutDate-datePeriod"
                label-cols-md="3"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-blockOutDate-datePeriod"
                    v-model="datePeriod"
                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, mode: 'range', dateFormat: 'd/m/Y', minDate: 'today', disableMobile: true }"
                    @input="datePeriodValidation == true ? datePeriodValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="datePeriodValidation"
                  class="text-danger"
                >
                  {{ datePeriodError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <validation-provider
                #default="{ errors }"
                name="Applies To"
                vid="applies_to"
                rules="required"
              >
                <b-form-group
                  label="Applies To*"
                  label-for="h-blockOutDate-activityTypes"
                  label-cols-md="2"
                  :state="(errors.length > 0 || activityTypeValidation) ? false : null"
                >
                  <!-- <v-select
                    id="h-blockOutDate-activityTypes"
                    v-model="activityTypes"
                    label="typeName"
                    multiple
                    :options="activityTypeOptions"
                    :reduce="activityType => activityType._id"
                    @input="activityTypeValidation == true ? activityTypeValidation = false : null"
                  /> -->
                  <b-form-tags
                    v-model="activityTypes"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveActivityName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose an Activity Type....
                          </option>
                        </template>
                        <option
                          v-for="(activity, ind) in activityTypeOptions"
                          :key="ind"
                          :value="activity._id"
                        >
                          {{ activity.typeName }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="activityTypeValidation"
                    class="text-danger"
                  >
                    {{ activityTypeError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-blockOutDate-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-blockOutDate-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-block-out-dates-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Block Out Date</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BInputGroup, BInputGroupAppend,
  BFormTags, BFormTag, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BFormTag,
    BFormSelect,

    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      datePeriod: '',
      name: '',
      activityTypes: [],
      status: 'active',
      datePeriodError: 'Valid date period is required',
      datePeriodValidation: false,
      nameError: 'Valid operating hours is required',
      nameValidation: false,
      activityTypeError: 'Valid applicable days is required',
      activityTypeValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      activityTypeOptions: [],

      // validation rules
      required,
    }
  },
  beforeMount() {
    this.$http.get('appointment/block-out-dates/respond-with/activity-types')
      .then(response => {
        this.activityTypeOptions = response.data.activityTypes || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveActivityName(activity) {
      const type = this.activityTypeOptions.find(o => o._id === activity)
      if (type) {
        return type.typeName
      }
      return ''
    },
    submitForm() {
      this.$refs.blockOutDateCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('datePeriod', this.datePeriod)
          formData.append('name', this.name)
          formData.append('status', this.status)

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.activityTypes.length; i++) {
            formData.append('activityTypes[]', this.activityTypes[i])
          }

          this.$http.post('appointment/block-out-dates/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Block out Date Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-block-out-dates-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'datePeriod') {
                    this.datePeriodError = validationError.msg
                    this.datePeriodValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'name') {
                    this.startTimeError = validationError.msg
                    this.startTimeValidation = true
                  } else if (validationError.param === 'activityTypes') {
                    this.activityTypeError = validationError.msg
                    this.activityTypeValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
